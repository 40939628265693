import React, { useState } from 'react';
import './inventoryitemmodal.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Stack from 'react-bootstrap/esm/Stack';
import Container from 'react-bootstrap/esm/Container';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/esm/Image';
import Form from 'react-bootstrap/Form';

const InventoryItemModal = ({ show, onHide, item }) => {
    const imageURLs = [item.image_path1, item.image_path2, item.image_path3].filter(url => url !== '');
    const [showDefaultContent, setShowDefaultContent] = useState(true);
    const [showInquiryContent, setShowInquiryContent] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleInquireClick = () => {
        setShowDefaultContent(false);
        setShowInquiryContent(true);
    };

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        const inventoryName = item.name;
        const id = item.id;

        if (form.checkValidity() === false || form.name.value !== '') {
            event.preventDefault();
            event.stopPropagation();
            console.log("Invalid");
        } else {
            event.preventDefault();
            const formData = new FormData(form);
            formData.append('inventory-name', inventoryName);
            formData.append('id', id);
            console.log(formData);
            try {
                const response = await fetch('https://www.oldhouseparts.com/sendEmail.php', {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    console.log('Email sent successfully!');
                    setShowSuccessModal(true); // Show success modal
                } else {
                    console.error('Failed to send email.');
                }
            } catch (error) {
                console.error('Error sending email:', error);
            }
        }
        setValidated(true);
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false); // Hide success modal
    };

    const handleBackClick = () => {
        setShowDefaultContent(true);
        setShowInquiryContent(false);
    }

    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={onHide}>
                <Container>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Stack direction='horizontal'>
                                <h4>{item.name}</h4>
                                <h6 className='id ps-3'>ID: {item.id}</h6>
                            </Stack>

                        </Modal.Title>
                    </Modal.Header>

                    {showDefaultContent && (
                        <>
                            <Modal.Body>
                                <Row xs={1} lg={2} className="g-4">
                                    <Col>
                                        {imageURLs.length > 1 ? (
                                            <Carousel className='carousel'>
                                                {imageURLs.map((url, index) => (
                                                    <Carousel.Item key={index} interval={3000}>
                                                        <Image src={url} fluid />
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        ) : (
                                            imageURLs.length === 1 && (
                                                <Image src={imageURLs[0]} fluid />
                                            )
                                        )}
                                    </Col>
                                    <Col >
                                        <Stack direction='horizontal'><p>{item.description}</p></Stack>
                                        <Stack direction='horizontal'>
                                            <h6>Price:&nbsp;&nbsp;</h6>
                                            <p>${item.price}</p>
                                        </Stack>
                                        <Stack direction='horizontal'>
                                            <h6>Quantity:&nbsp;&nbsp;</h6>
                                            {item.quantity !== "0" ? (
                                                <p>{item.quantity}</p>
                                            ) : (
                                                <p>1</p>
                                            )}
                                        </Stack>
                                        <Stack direction='horizontal'>
                                            {item.dimensionW !== '0' && item.dimensionW !== null ? (
                                                <>
                                                    <h6>W:&nbsp;</h6>
                                                    {/* Gets rid of the trailing zeros */}
                                                    <p>{parseFloat(item.dimensionW).toFixed(10).toString().replace(/\.?0+$/, '')}''&nbsp;&nbsp;</p>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            {item.dimensionH !== '0' && item.dimensionH !== null ? (
                                                <>
                                                    <h6>H:&nbsp;</h6>
                                                    {/* Gets rid of the trailing zeros */}
                                                    <p>{parseFloat(item.dimensionH).toFixed(10).toString().replace(/\.?0+$/, '')}''&nbsp;&nbsp;</p>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            {item.dimensionD !== '0' && item.dimensionD !== null ? (
                                                <>
                                                    <h6>D:&nbsp;</h6>
                                                    {/* Gets rid of the trailing zeros */}
                                                    <p>{parseFloat(item.dimensionD).toFixed(10).toString().replace(/\.?0+$/, '')}''&nbsp;&nbsp;</p>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Stack>
                                        <Stack direction='horizontal'>
                                            {item.door_panels !== "" && item.door_panels !== null ? (
                                                <>
                                                    <h6>Door Panels:&nbsp;&nbsp;</h6>
                                                    <p>{item.door_panels}</p>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Stack>
                                        <Stack direction='horizontal'>
                                            <h6>Shipping:&nbsp;&nbsp;</h6>
                                            {item.shipping !== "0" ? (
                                                <p>Available</p>
                                            ) : (
                                                <p>Pickup Only</p>
                                            )}
                                        </Stack>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={handleInquireClick}><h4>Inquire</h4></Button>
                            </Modal.Footer>
                        </>
                    )}
                    {showInquiryContent && (
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label><h5>Name</h5></Form.Label>
                                    <Form.Control type="text" name="ohp-name" autoComplete="on" placeholder="First & Last name" required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                    <Form.Label><h5>Email Address</h5></Form.Label>
                                    <Form.Control type="email" name="email" autoComplete="on" placeholder="name@example.com" required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label><h5>Message</h5></Form.Label>
                                    <Form.Control as="textarea" name="message" autoComplete="off" rows={3} placeholder={`Please provide any questions or comments about the item here`} required />
                                </Form.Group>

                                {/* Honeypot field */}
                                <Form.Group className="mb-3 not-today">
                                    <Form.Label><h5>Name</h5></Form.Label>
                                    <Form.Control type="text" name="name" autoComplete="off" />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className='me-3' onClick={handleBackClick}><h4>Back</h4></Button>
                                <Button type="submit"><h4>Send</h4></Button>
                            </Modal.Footer>

                            <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Email Sent Successfully!</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Your email has been sent successfully.
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseSuccessModal}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                    )}
                </Container>
            </Modal>
        </>
    )
}

export default InventoryItemModal
