import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Map = () => {
    const mapContainerStyle = {
        width: '100%',
        height: '400px',
    };

    const center = {
        lat: 43.381980,
        lng: -70.529280,
    };

    

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyAOKyd1lf2PVxX2qoJScvYY9p7IyMPryyM"
            libraries={['places']}
        >
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={16}
            >
                <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    )
}

export default Map
