import React from 'react';
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import FAQ from "../../components/faq/FAQ";
import "./faqs.css";

const FAQs = () => {
  return (
    <div className='faqs'>
      <Header />
      <FAQ/>
      <Footer/>
    </div>
  )
}

export default FAQs
