import React, { useState } from 'react';
import axios from 'axios';
import './inventorynav.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import filter from "../../assets/filter-icon.svg";
import Image from "react-bootstrap/Image";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

const InventoryNav = ({ onNavItemSelect, onLatestClick }) => {
    const [filterDropdown, setFilterDropdown] = useState(false);

    const handleNavItemClick = (category, subCategory) => {
        axios.get('https://www.oldhouseparts.com/getInventory.php', {
            params: {
                category,
                subCategory,
            },
        })
            .then(response => {
                console.log(response.data)
                onNavItemSelect(response.data, category, subCategory)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const [filterData, setFilterData] = useState({
        inventoryNumber: '',
        name: '',
        priceMin: '',
        priceMax: '',
        heightMin: '',
        heightMax: '',
        widthMin: '',
        widthMax: '',
        depthMin: '',
        depthMax: '',
        doorPanels: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFilterData({ ...filterData, [name]: value });
    };

    const resetFilterData = () => {
        setFilterData({
            name: '',
            inventoryNumber: '',
            priceMin: '',
            priceMax: '',
            heightMin: '',
            heightMax: '',
            widthMin: '',
            widthMax: '',
            depthMin: '',
            depthMax: '',
            doorPanels: ''
        });
    }

    const handleFilterSearch = () => {
        axios.get('https://www.oldhouseparts.com/getFiltered.php', {
            params: {
                filterData,
            },
        })
            .then(response => {
                console.log(response.data)
                onNavItemSelect(response.data, "Search", "");
                setFilterDropdown(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    return (
        <div className='inventory-nav fixed-top'>
            <Container>
                <Nav className='pb-3'>
                    <Dropdown onClick={onLatestClick}>
                        <Dropdown.Toggle variant="none" id="latest-dropdown">
                            <h5>Latest</h5>
                        </Dropdown.Toggle>
                    </Dropdown>

                    <Dropdown onClick={() => handleNavItemClick('Antiques', 'Antique')}>
                        <Dropdown.Toggle variant="none" id="antiques-dropdown">
                            <h5>Antiques</h5>
                        </Dropdown.Toggle>
                    </Dropdown>

                    {/* Doors Dropdown */}
                    <Dropdown>
                        <Dropdown.Toggle variant="none" id="doors-dropdown">
                            <h5>Doors</h5>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleNavItemClick('Doors', 'Exterior')}><p>Exterior</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Doors', 'Interior')}><p>Interior</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Doors', 'Screen and Storm')}><p>Screen and Storm</p></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* Windows Dropdown */}
                    <Dropdown>
                        <Dropdown.Toggle variant="none" id="windows-dropdown">
                            <h5>Windows</h5>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleNavItemClick('Windows', 'Stained and Leaded')}><p>Stained and Leaded</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Windows', 'Other')}><p>Other</p></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* Plumbing Dropdown */}
                    <Dropdown>
                        <Dropdown.Toggle variant="none" id="plumbing-dropdown">
                            <h5>Plumbing</h5>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleNavItemClick('Plumbing', 'Bathtubs')}><p>Bathtubs</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Plumbing', 'Sinks')}><p>Sinks</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Plumbing', 'Fixtures')}><p>Fixtures</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Plumbing', 'Accessories')}><p>Accessories</p></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* Hardware Dropdown */}
                    <Dropdown>
                        <Dropdown.Toggle variant="none" id="hardware-dropdown">
                            <h5>Hardware</h5>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleNavItemClick('Hardware', 'Doorknobs & Locksets')}><p>Doorknobs & Locksets</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Hardware', 'Doorbells & Knockers')}><p>Doorbells & Knockers</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Hardware', 'Escutcheons')}><p>Escutcheons</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Hardware', 'Pulls and Handles')}><p>Pulls and Handles</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Hardware', 'Other')}><p>Other</p></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* Lighting Dropdown */}
                    <Dropdown>
                        <Dropdown.Toggle variant="none" id="lighting-dropdown">
                            <h5>Lighting</h5>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleNavItemClick('Lighting', 'Sconces')}><p>Sconces</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Lighting', 'Shades and Globes')}><p>Shades and Globes</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Lighting', 'Flush and Semi-Flush')}><p>Flush and Semi Flush</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Lighting', 'Hanging')}><p>Hanging</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Lighting', 'Other')}><p>Other</p></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* Other Dropdown */}
                    <Dropdown>
                        <Dropdown.Toggle variant="none" id="other-dropdown">
                            <h5>Other</h5>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleNavItemClick('Other', 'Architectural Elements')}><p>Architectural Elements</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Other', 'Heat and Fireplace')}><p>Heat and Fireplace</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Other', 'Stone, Garden, and Masonry')}><p>Stone, Garden, and Masonry</p></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleNavItemClick('Other', 'Newel Posts and Stair Parts')}><p>Newel Posts and Stair Parts</p></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* Filter Dropdown */}
                    <Dropdown show={filterDropdown}>
                        <Dropdown.Toggle variant="none" id="filter-dropdown" onClick={() => setFilterDropdown(!filterDropdown)}>
                            <div className='d-flex align-center'>
                                <Image src={filter} fluid></Image>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='filter-dropdown'>
                            <Form>
                                <Form.Group className='m-3' controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter name"
                                        name="name"
                                        value={filterData.name}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                <Form.Group className='m-3' controlId="formInventoryNumber">
                                    <Form.Label>Inventory ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter ID"
                                        name="inventoryNumber"
                                        value={filterData.inventoryNumber}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>


                                <Form.Group className='m-3' controlId="formPrice">
                                    <Form.Label>Price</Form.Label>
                                    <Stack direction='horizontal'>
                                        <Form.Control
                                            type="text"
                                            placeholder="min"
                                            name="priceMin"
                                            value={filterData.priceMin}
                                            onChange={handleInputChange}
                                        />
                                        <p className='my-0 px-2'>-</p>
                                        <Form.Control
                                            type="text"
                                            placeholder="max"
                                            name="priceMax"
                                            value={filterData.priceMax}
                                            onChange={handleInputChange}
                                        />
                                    </Stack>
                                </Form.Group>
                                <Form.Group className='m-3' controlId="formPrice">
                                    <Form.Label>Height</Form.Label>
                                    <Stack direction='horizontal'>
                                        <Form.Control
                                            type="text"
                                            placeholder="min"
                                            name="heightMin"
                                            value={filterData.heightMin}
                                            onChange={handleInputChange}
                                        />
                                        <p className='my-0 px-2'>-</p>
                                        <Form.Control
                                            type="text"
                                            placeholder="max"
                                            name="heightMax"
                                            value={filterData.heightMax}
                                            onChange={handleInputChange}
                                        />
                                    </Stack>
                                </Form.Group>
                                <Form.Group className='m-3' controlId="formPrice">
                                    <Form.Label>Width</Form.Label>
                                    <Stack direction='horizontal'>
                                        <Form.Control
                                            type="text"
                                            placeholder="min"
                                            name="widthMin"
                                            value={filterData.widthMin}
                                            onChange={handleInputChange}
                                        />
                                        <p className='my-0 px-2'>-</p>
                                        <Form.Control
                                            type="text"
                                            placeholder="max"
                                            name="widthMax"
                                            value={filterData.widthMax}
                                            onChange={handleInputChange}
                                        />
                                    </Stack>
                                </Form.Group>
                                <Form.Group className='m-3' controlId="formPrice">
                                    <Form.Label>Depth</Form.Label>
                                    <Stack direction='horizontal'>
                                        <Form.Control
                                            type="text"
                                            placeholder="min"
                                            name="depthMin"
                                            value={filterData.depthMin}
                                            onChange={handleInputChange}
                                        />
                                        <p className='my-0 px-2'>-</p>
                                        <Form.Control
                                            type="text"
                                            placeholder="max"
                                            name="depthMax"
                                            value={filterData.depthMax}
                                            onChange={handleInputChange}
                                        />
                                    </Stack>

                                </Form.Group>
                                    <Form.Group className='m-3' controlId="formInventoryNumber">
                                        <Form.Label>Door Panels</Form.Label>
                                        <Form.Select
                                            className='form-control'
                                            name="doorPanels"
                                            value={filterData.doorPanels}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select number of panels</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7+</option>
                                        </Form.Select>
                                    </Form.Group>
                            </Form>
                            <div className='d-flex justify-content-between'>
                                <Button className='my-4 mx-3' variant='primary' onClick={handleFilterSearch}>
                                    Search
                                </Button>
                                <Button className='my-4 mx-3' variant='secondary' onClick={resetFilterData}>
                                    Clear
                                </Button>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
            </Nav>
        </Container>
        </div >
    );
};

export default InventoryNav;
