import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/logo.png";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Image from "react-bootstrap/Image";
import Nav from 'react-bootstrap/Nav';


const Header = ({ variant }) => {
    const [displayHeader, setDisplayHeader] = useState(variant === 'home');
    const location = useLocation()
    const navigate = useNavigate();

    const handleNavItemClick = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const isVariantHome = variant === 'home';

            if (scrollPosition < 600 && isVariantHome) {
                setDisplayHeader(true);
            } else {
                setDisplayHeader(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [variant]);

    return (
        <Navbar collapseOnSelect fixed="top" style={{
            backgroundColor: 'var(--color-dark)',
            display: displayHeader ? 'none' : '',
        }} data-bs-theme="dark" className='header nav-bar bg py-3' expand="lg">
            <Container>
                <Navbar.Brand to="/">
                    <Link to="/" onClick={() => handleNavItemClick('/')}>
                        <Image className='logo' src={logo} fluid />
                    </Link>

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                    <Nav className=' text-end' activeKey={location.pathname}>
                        <Nav.Item className='px-4'>
                            <Link to="/" className={location.pathname === '/' ? 'active py-1' : 'py-1'} onClick={() => handleNavItemClick('/')}>
                                Home
                            </Link>
                        </Nav.Item>
                        <Nav.Item className='px-4'>
                            <Link to="/inventory" className={location.pathname === '/inventory' ? 'active py-1' : 'py-1'} onClick={() => handleNavItemClick('/inventory')}>
                                Inventory
                            </Link>
                        </Nav.Item>
                        <Nav.Item className='about-link px-4'>
                            <Link to="/about" className={location.pathname === '/about' ? 'active py-1' : 'py-1'} onClick={() => handleNavItemClick('/about')}>
                                About
                            </Link>
                        </Nav.Item>
                        <Nav.Item className='about-link px-4'>
                            <Link to="/faqs" className={location.pathname === '/faqs' ? 'active py-1' : 'py-1'} onClick={() => handleNavItemClick('/faqs')}>
                                FAQs
                            </Link>
                        </Nav.Item>
                        <Nav.Item className='px-4'>
                            <Link to="/contact" className={location.pathname === '/contact' ? 'active py-1' : 'py-1'} onClick={() => handleNavItemClick('/contact')}>
                                Contact
                            </Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header