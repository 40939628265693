import React, { useState } from 'react';
import "./inventorycell.css";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import Row from 'react-bootstrap/Row';
import InventoryItemModal from '../inventory-item-model/InventoryItemModal';

const InventoryCell = ({ item }) => {
    const [modalShow, setModalShow] = useState(false);

    const handleItemClick = (item) => {
        setModalShow(true)
        console.log(item)
    }

    return (
        <div className="inventory-cell mx-auto py-4">
            <Card>
                <button className='inventory-cell-btn' onClick={() => handleItemClick(item)}>
                    <Card.Img loading="lazy" variant="top" src={`https://www.oldhouseparts.com/${item.image_path1}`} />
                    <Card.Body>
                        <Card.Text className='cell-text'>
                            <Row>
                                <div className="inventory-name-text justify-content-start d-flex pb-1">{item.name}</div>
                            </Row>
                            <Row>
                                <Stack direction="horizontal">
                                    <div className="inventory-id">#{item.id}</div>
                                    <div className="ms-auto">${item.price}</div>
                                </Stack>
                            </Row>

                        </Card.Text>
                    </Card.Body>
                </button>
            </Card>

            <InventoryItemModal show={modalShow} onHide={() => setModalShow(false)} item={item} />
        </div >
    )
}

export default InventoryCell