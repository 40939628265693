import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import "./gallery.css";
import store from "../../assets/gallery-img/store.png";
import hardware from "../../assets/gallery-img/hardware.png";
import workspace from "../../assets/gallery-img/workspace.png";
import knobs from "../../assets/gallery-img/knobs.png";
import doors from "../../assets/gallery-img/doors.png";
import Container from 'react-bootstrap/esm/Container';

const Gallery = () => {
    return (
        
        // Images must be the same aspect ratio (1400x980)

        <div className='gallery'>
            <Container>
                <h2 className='text-center pb-5'>Gallery</h2>
                <Carousel className='carousel' >
                    <Carousel.Item  className='carousel-img' interval={3000}>
                        <Image loading="lazy" src={store} fluid/>
                    </Carousel.Item>
                    <Carousel.Item className='carousel-img' interval={3000}>
                        <Image loading="lazy" src={workspace} fluid/>
                    </Carousel.Item>
                    <Carousel.Item className='carousel-img' interval={3000}>
                        <Image loading="lazy" src={hardware} fluid/>
                    </Carousel.Item>
                    <Carousel.Item className='carousel-img' interval={3000}>
                        <Image loading="lazy" src={doors} fluid/>
                    </Carousel.Item>
                    <Carousel.Item className='carousel-img' interval={3000}>
                        <Image loading="lazy" src={knobs} fluid/>
                    </Carousel.Item>
                </Carousel>
            </Container>
        </div>
    )
}

export default Gallery
