import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import bg1 from "../../assets/home-bg-img/bg1.webp";
import "./homelanding.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import logo from "../../assets/logo.png";

const HomeLanding = () => {
  const navigate = useNavigate();


  const handleNavItemClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };


  return (
    <Container >
      <img className='home-bg' src={bg1} alt='background image'></img>
      <Col className='home-content text-center'>
        <Stack className='justify-content-center d-flex' direction='horizontal'>
          <Link className='mx-lg-5 mx-md-4 mx-sm-3 mx-2' to='/' >
            <h5>Home</h5>
          </Link>
          <Link className='mx-lg-5 mx-md-4 mx-sm-3 mx-2' to='/inventory'>
            <h5>Inventory</h5>
          </Link>
          <Link className='mx-lg-5 mx-md-4 mx-sm-3 mx-2' to='/about'>
            <h5>About</h5>
          </Link>
          <Link className='mx-lg-5 mx-md-4 mx-sm-3 mx-2' to='/faqs'>
            <h5>FAQs</h5>
          </Link>
          <Link className='mx-lg-5 mx-md-4 mx-sm-3 mx-2' to='/contact'>
            <h5>Contact</h5>
          </Link>
        </Stack>
        <Row className="justify-content-center">
          <img className='logo' src={logo} alt="Logo" />
        </Row>
        <p className='pb-lg-4'>From antique windows, doors, and hardware, to stained
          glass, lighting, and more, our diverse collection is
          cherished by homeowners, builders, designers, architects,
          and artists everywhere.</p>
        <Link to="/inventory" onClick={() => handleNavItemClick('/inventory')}>
          <Button variant="outline-light">
            <h2 className='px-3 py-2'>Shop Antiques</h2>
          </Button>
        </Link>
      </Col>
    </Container>
  );
};

export default HomeLanding;
