import React, { useState, useEffect } from 'react';
import { InventoryCell } from '../../components';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';
import "./inventorycontainer.css";

const InventoryContainer = ({ inventoryData, category, subCategory }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 16;

    const totalPages = Math.ceil(inventoryData.length / itemsPerPage);

    const getPaginatedItems = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return inventoryData.slice(startIndex, endIndex);
    };

    const handlePageChange = async (page) => {
        await setCurrentPage(page);
        window.scrollTo(0,0)
    };

    useEffect(()  => {
        setCurrentPage(1);
        window.scrollTo(0,0);
    }, [inventoryData]);


    return (
        <div>
            <Container className='inventory-cell-container'>
                <Row><h1 className='ps-4'>{category}</h1></Row>
                <Row>
                    {subCategory === 'Antique' ? (
                        <div className='pt-3'></div>
                    ) : (
                        <h5 className='ps-4 pb-1 pt-2'>{subCategory}</h5>
                    )}
                </Row>
                <Row xs={1} sm={2} lg={3} xl={4}>
                    {getPaginatedItems().length > 0 ? (
                        getPaginatedItems().map(item => (
                            <Col className="inventory-list" key={item.id}>
                                <InventoryCell item={item} />
                            </Col>
                        ))
                    ) : (
                        <Col className='m-auto'>
                            <h5 className='no-items-message text-center'>Unfortunately we don't have any of these items available online at the moment.
                                Contact us if you have a specific item you are looking for.</h5>
                        </Col>
                    )}

                </Row>
                {/* Pagination controls */}
                {totalPages > 1 && (
                    <Row className="mt-5 pagination">
                        <Col className="text-center">
                            <Button variant="outline-secondary" className='mx-3' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                Previous
                            </Button>
                            <span>Page {currentPage} of {totalPages}</span>
                            <Button variant="outline-secondary" className='mx-3' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                Next
                            </Button>
                        </Col>
                    </Row>
                )}
            </Container>
        </div >
    )
}

export default InventoryContainer;