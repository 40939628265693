import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { InventoryCell } from '..';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./latestinventorycontainer.css";

const LatestInventoryContainer = () => {
    const [newInventoryData, setNewInventoryData] = useState([])

    const fetchRecentData = () => {
        axios.get('https://www.oldhouseparts.com/getRecent.php')
            .then(response => {
                console.log(response.data)
                setNewInventoryData(response.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }

    useEffect(() => {
        fetchRecentData();
    }, []);

    return (
        <div>
            <Container className='inventory-cell-container'>
                <Row><h1 className='ps-4 pb-3'>Latest Inventory</h1></Row>
                <Row xs={1} sm={2} lg={3} xl={4}>
                    {newInventoryData.length > 0 ? (
                        newInventoryData.map(item => (
                            <Col className="inventory-list" key={item.id}>
                                <InventoryCell item={item} />
                            </Col>
                        ))
                    ) : (
                        <Col className='m-auto'>
                            <h5 className='no-items-message text-center'>Unfortunately we don't have any of these items available online at the moment.
                                Contact us if you have a specific item you are looking for.</h5>
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    )
}

export default LatestInventoryContainer