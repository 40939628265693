import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/esm/Image';
import "./press.css"
import mainevisit from "../../assets/press/visit-maine.svg";
import downeast from "../../assets/press/downeast.svg";
import newengland from "../../assets/press/new-england.svg";
import travelawaits from "../../assets/press/travel-awaits.svg";
import vacation from "../../assets/press/vacation-idea.svg";

const press = () => {
    return (
        <div className='press'>
            <Container>
                <Col>
                    <Row>
                        <h1 className='text-center fw-bold'>Press</h1>
                    </Row>
                    <Row className='pt-5 px-auto justify-content-center align-items-center' xs={1} lg={3}>
                        <Col className='press-img p-4 px-5 text-center'><Nav.Link href="https://www.travelawaits.com/2495499/best-new-england-antique-stores/"><Image loading="lazy" src={travelawaits} fluid /></Nav.Link></Col>
                        <Col className='press-img p-4 px-5 text-center'><Nav.Link href="https://newengland.com/living/homes/old-house-parts/ "><Image loading="lazy" src={newengland} fluid /></Nav.Link></Col>
                        <Col className='press-img p-4 px-5 text-center'><Nav.Link href="https://downeast.com/best-of-maine/best-of-maine-2018/"><Image loading="lazy" src={downeast} fluid /></Nav.Link></Col>
                    </Row>
                    <Row className='py-5 px-auto justify-content-center align-items-center bottom-row' xs={1} lg={3}>
                        <Col className='press-img p-4 px-5 text-center'><Nav.Link href="https://vacationidea.com/maine/best-things-to-do-in-kennebunk-me.html"><Image loading="lazy" src={vacation} fluid /></Nav.Link></Col>
                        <Col className='press-img p-4 px-5 text-center'><Nav.Link href="https://www.visitmaine.net/things-to-do-kennebunk-kennebunk-beach/"><Image loading="lazy" src={mainevisit} fluid /></Nav.Link></Col>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

export default press
