import React from 'react';
import {Helmet} from 'react-helmet-async';
import metaImage from "../../assets/gallery-img/store.png";

const InventoryHelmet = () => {
    const socialLinks = {
        facebook: 'https://www.facebook.com/oldhousepartsco/',
        instagram: 'https://www.instagram.com/oldhousepartsco/',
        youtube: 'https://www.youtube.com/channel/UCJ0NXly6M11FTWWmKQS3Piw',
      };
    
      return (
        <div>
          <Helmet>
            <title>Old House Parts - Inventory</title>
            <meta name="description" content="From antique windows, doors, and hardware, to stained glass, lighting, and more, our diverse collection is cherished by homeowners, builders, designers, architects, and artists everywhere." />
            <meta name="keywords" content="Old House Parts, Antique Shop, Doors, Stained Glass, Windows, Plumbing, Sinks, Fixtures, Bathtubs, Knobs, Locksets, Escutcheons, Hardware, Lighting, Shades, Sconces, Architectural Elements, Fireplace, Stone, Garden, Masonry, Stair Posts" />
    
            <meta property="og:url" content="https://www.oldhouseparts.com" />
            <meta property="og:title" content="Old House Parts Co." />
            <meta property="og:description" content="Explore our collection of vintage furniture, art, and collectibles at Old House Parts Co. in Kennebunk, Maine." />
            <meta property="og:image" content={metaImage} />
    
            {/* Facebook */}
            <meta property="article:publisher" content={socialLinks.facebook} />
            {/* Instagram */}
            <meta property="instagram:site" content={socialLinks.instagram} />
            {/* YouTube */}
            <meta property="youtube:channel" content={socialLinks.youtube} />
          </Helmet>
        </div>
      )
}

export default InventoryHelmet
