import React from 'react'
import { Header, HomeLanding, VisitUs, Gallery, Footer, GetInTouch, HomeHelmet } from '../../components';
import "./home.css";

const Home = () => {
  return (
    <div className='home'>
      <HomeHelmet />
      <Header variant={'home'} />
      <HomeLanding />
      <VisitUs />
      <Gallery />
      <GetInTouch />
      <Footer />
    </div>

  )
}

export default Home
